import { NgModule } from '@angular/core';
import { UiModule } from '@freelancer/ui';
import { ModalModule } from '@freelancer/ui/modal';
import { AppModalsModule } from 'app/app-modals.module';
import { CompatModalsComponent } from './compat-modals.component';

@NgModule({
  imports: [UiModule, ModalModule, AppModalsModule],
  declarations: [CompatModalsComponent],
})
export class CompatModalsModule {}
